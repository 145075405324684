
@mixin gradientAnimation(){
  background-size: 100%;
  background-image: none;
  position: relative;
  z-index: 100;
  &:before {
    background : -moz-linear-gradient(100.6% 94.54% 157.16deg,rgba(94, 112, 155, 1) 0.15%,rgba(39, 63, 102, 1) 34.3%,rgba(23, 41, 66, 1) 100%);
    background : -webkit-linear-gradient(157.16deg, rgba(94, 112, 155, 1) 0.15%, rgba(39, 63, 102, 1) 34.3%, rgba(23, 41, 66, 1) 100%);
    background : -webkit-gradient(linear,100.6% 94.54% ,3.56% 9.12% ,color-stop(0.0015,rgba(94, 112, 155, 1) ),color-stop(0.343,rgba(39, 63, 102, 1) ),color-stop(1,rgba(23, 41, 66, 1) ));
    background : -o-linear-gradient(157.16deg, rgba(94, 112, 155, 1) 0.15%, rgba(39, 63, 102, 1) 34.3%, rgba(23, 41, 66, 1) 100%);
    background : -ms-linear-gradient(157.16deg, rgba(94, 112, 155, 1) 0.15%, rgba(39, 63, 102, 1) 34.3%, rgba(23, 41, 66, 1) 100%);
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#5E709B', endColorstr='#172942' ,GradientType=0)";
    background : linear-gradient(-67.16deg, rgba(94, 112, 155, 1) 0.15%, rgba(39, 63, 102, 1) 34.3%, rgba(23, 41, 66, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5E709B',endColorstr='#172942' , GradientType=1);
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0; left: 0;
    opacity: 0;
    width: 100%;
    z-index: -100;
    transition: opacity 250ms;
  }
  .show-menu &,
  &.fill {
      &:before {
          opacity: 1;
      }
  }
}


  
  .navbar{
    background: transparent;
    transition: all 250ms ease-in;

    padding: $grid-gutter-width $grid-gutter-width;
    @include gradientAnimation();
    
    .show-menu &,
    &.fill{
      -webkit-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.1);
      -moz-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.1);
      box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.1);
      .navbar-brand{
        opacity: 1;
      }
    }

    // &.menu-open {
    //   position: fixed;
    //   left: 0;
    //   right: 0;
    //   background-color: theme-color("secondary");
    // }
    .navbar-brand{
      opacity: 0;
      // transition: opacity 250ms;
      // @include media-breakpoint-up(lg) {
      //   width: auto;
      //   margin: 0;
      // }
      &.show{
        opacity: 1;
      }
    }
    .navbar-nav{
      height: calc(100vh - 107px);
      background-color: $hex-blue;
      position: fixed;
      top: 107px;
      left: 0;
      width: 100%;
      transition: $transition-base;
      transform: translateX(100%);
      .show-menu & {
        transform: translateX(0);
      }
      @media (hover: none) {
        height: calc(var(--vh, 1vh) * 100 - 107px);
      }
      @include media-breakpoint-up(lg) {
        position: static;
        width: auto;
        height: auto;
        transform: translateX(0);
        background-color: transparent;
        margin-left: auto;
      }
      .nav-item{
        @include media-breakpoint-up(lg) {
          margin: auto;
        }
        // margin-bottom: 10px;
        // padding: 0 $grid-gutter-width/4;

        // @include media-breakpoint-up(md) {
        //   margin: 0;
        // }
        // @include media-breakpoint-up(xl) {
        //   padding: 0 $grid-gutter-width;
        // }
        .nav-link {
          padding: $spacer*.75 $grid-gutter-width;
          font-size: $font-size-base*1.125;
          white-space: nowrap;
          @include media-breakpoint-up(lg) {
            padding: 5px $grid-gutter-width/2;
            font-size: $font-size-base;
          }
          color: $white;
          // @include media-breakpoint-up(md) {
          //   padding: $grid-gutter-width/2 0;
          // }
          // border-bottom: 3px solid $body-bg;
          &:hover{
            color: theme-color("secondary");
          }
          &.dropdown-toggle {
            z-index: 2;
            padding-right: $spacer*2.875;
            @include media-breakpoint-up(lg) {
              padding-right: $grid-gutter-width/2;
            }
            &::before {
              content: "";
              position: absolute;
              top: -$spacer*.5;
              left: -$spacer;
              height: calc(100% + #{$spacer} + 1px);
              width: calc(100% + #{$spacer} * 1.75);
              border-left: 1px solid rgba($white, .1);
              border-right: 1px solid rgba($white, .1);
              border-bottom: 1px solid $white;
              display: none;
              z-index: 1;
            }
            &::after {
              border: none;
              @include media-breakpoint-up(lg) {
                width: 13px;
                height: 12px;
                position: absolute;
                right: 4px;
                top: 12px;
                margin: 0 0 (-$spacer*.25) $spacer*.5;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='13' fill='%23d4d725'%3E%3Cpath d='M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z'/%3E%3C/svg%3E");
                position: static;
              }
            }
          }
          &.nav-link-back {
            cursor: pointer;
            position: relative;
            background-repeat: no-repeat;
            background-size: auto 16px;
            background-position: top 18px left 14px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' fill='%23d4d725'%3E%3Cpath d='M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z'/%3E%3C/svg%3E");
          }
        }
        &.current-page-ancestor,
        &.pre-active{
          > .nav-link{
            font-weight: 700;
          }
        }
        &.btn{
          padding-block: $spacer*.75;
          @include media-breakpoint-up(lg) {
            padding: 0;
            &:last-child{
              padding-right: 0;
            }
          }
          a{
            @extend .btn;
            @extend .btn-secondary;
          }
        }
        +.nav-item {
          border-top: 1px solid rgba($white, .6);
          @include media-breakpoint-up(lg) {
            border-top: none;
          }
        }
        &:last-child {
          &:not(.btn) {
            border-bottom: 1px solid rgba($white, .6);
            @include media-breakpoint-up(lg) {
              border-bottom: none;
            }
          }
        }
        &.dropdown {
          position: relative;
          .toggle-sub{
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 46px;
            z-index: 2;
            border-left: 1px solid rgba($white, .6);
            color: $white;
            background-repeat: no-repeat;
            background-size: auto 16px;
            background-position: top 18px right 18px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' fill='%23d4d725'%3E%3Cpath d='M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z'/%3E%3C/svg%3E");  
            @include media-breakpoint-up(lg) {
              display: none;
            }

            @media (hover: hover) {
              cursor: pointer;
              &:hover{
                background-color: rgba($white, .2);
              }
            }
          }

          .nav-sub {
            list-style: none;
            width: 100%;
            background-color: theme-color('primary');
            height: calc(100vh - 107px);
            position: fixed;
            top: 0;
            width: 100%;
            transform: translateX(100%);
            transition: $transition-base;
            display: flex;
            flex-direction: column;
            left: 0;
            z-index: $zindex-fixed;
            padding-inline: 0;
            @media (hover: none) {
              height: calc(var(--vh, 1vh) * 100 - 107px);
            }
            @include media-breakpoint-up(lg) {
              display: none;
              position: absolute;
              height: auto;
              width: auto;
              transform: translateX(0);
              padding-block: $grid-gutter-width/2;
              top: calc(100% + 8px);
              background-color: $white;
              box-shadow: 0 10px 10px 0 rgba($hex-blue,0.12);
            }
            &.show {
              transform: translateX(0);
              @include media-breakpoint-up(lg) {
                display: block;
              }
            }
            .nav-item {
              .nav-link {
                @include media-breakpoint-up(lg) {
                  padding: 5px $grid-gutter-width;
                  color: theme-color('primary');
                  &:hover {
                    background-color: rgba($hex-blue,0.05);
                  }
                }
              }
              &.nav-sub-left {
                left: calc(100% + 14px);
                &.show {
                  @include media-breakpoint-up(lg) {
                    transform: translateX(-100%);
                  }
                }
              }
              &.dropdown{

                .dropdown-toggle {
                  @include media-breakpoint-up(lg) {
                    &::after{
                      transform: rotate(-90deg);
                      margin-bottom: -$spacer*.325;
                    }
                  }
                }

                @include media-breakpoint-up(lg){
                  ul.nav-sub {
                    top: (-$grid-gutter-width * .5);
                    left: 100%;
                    &-left {
                      transform: translateX(-100%);
                      left: 0;
                      right: unset;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

.blog-header{
    background: linear-gradient(-67.16deg,#5e709b .15%,#273f66 34.3%,#172942);
    .title{
      color: $white;
    }
    .btn-link{
      color: rgba($white, .6);
      &:hover{
        color: $white;
      }
    }
    .back-link{
      &:hover{
        color: #b4b61f;
      }
    }
}