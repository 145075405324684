$enable-rounded:            false;
$enable-shadows:            false;
$enable-gradients:          false;
$enable-transitions:        true;
$enable-hover-media-query:  false;
$enable-grid-classes:       true;
$enable-print-styles:       true;

$border-radius:          2px;
$border-radius-lg:       2px;
$border-radius-sm:       2px;

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;
$grid-gutter-width:     30px;
$grid-gutter-width-lg:  60px;

$hex-blue:    #284067;
$hex-yellow:  #d4d725;

$white:       #fff;
$gray-100:    #f8f9fa;
$gray-200:    #e9ecef;
$gray-300:    #dee2e6;
$gray-400:    #ced4da;
$gray-500:    #adb5bd;
$gray-600:    #868e96;
$gray-700:    #495057;
$gray-800:    #343a40;
$gray-900:    #212529;

$black:       #000;
$blue:        #007bff;
$indigo:      #6610f2;
$purple:      #6f42c1;
$pink:        #e83e8c;
$red:         #dc3545;
$orange:      #fd7e14;
$yellow:      #ffc107;
$green:       #28a745;
$teal:        #20c997;
$cyan:        #17a2b8;

$twitter:     #1da1f2;
$facebook:    #3b5998;
$pinterest:   #bd081c;
$linkedin:    #0077b5;

$colors: (
  blue: $blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  gray: $gray-600,
  gray-dark: $gray-800
);

$theme-colors: (
  primary: $hex-blue,
  secondary: $hex-yellow,
  success: $green,
  info: $cyan,
  warning: $yellow,
  danger: $red,
  light: $gray-300,
  dark: $gray-800,
  twitter: $twitter,
  facebook: $facebook,
  pinterest: $pinterest,
  linkedin: $linkedin,
  blue: $blue,
  green: $green
);


$navbar-light-color:                $gray-800;
$navbar-light-hover-color:          $gray-800;
$navbar-light-active-color:         $gray-800;
$navbar-light-disabled-color:       rgba($gray-800,.3);
// $navbar-light-toggler-icon-bg:      str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23");
// $navbar-light-toggler-border-color: rgba($gray-800,1);

$enable-rounded:            false;
$enable-shadows:            false;
$enable-gradients:          false;
$enable-transitions:        true;
$enable-hover-media-query:  false;
$enable-grid-classes:       true;
$enable-print-styles:       true;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;
$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3),
  10: ($spacer * 6)
);

// Fonts
//
// Font, line-height, and color for body text, headings, and more.
$font-family-sans-serif: "IBM Plex Sans",  -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-family-serif: "IBM Plex Serif",  -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;


$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-lg:   1.375rem;
$font-size-sm:   .875rem;

$h1-font-size: 2.875rem; //45px
$h2-font-size: 2.25rem;  //36px
$h3-font-size: 1.875rem; //30px
$h4-font-size: 1.5rem;   //24px
$h5-font-size: 1.25rem;  //20px
$h6-font-size: 1rem;     //16px

$font-weight-normal:      300;
$font-weight-bold:        600;
$headings-font-weight:    300;

$line-height-base:        1.5;
$headings-line-height:    1.2;

$body-color:              $hex-blue;
$body-bg:                 $white;
$headings-color:          $hex-blue;

$alert-padding-y:             1rem;
$alert-padding-x:             1.25rem;
$alert-margin-bottom:         1rem;
$alert-border-radius:         0;
$alert-link-font-weight:      $font-weight-bold;
$alert-border-width:          0;

// $input-border-radius:            0;
// $input-border-radius-lg:         0;
// $input-border-radius-sm:         0;

//BUTTON
$input-btn-border-width:            2px;
// $btn-font-weight:                   700;
$input-btn-padding-y:       .5625rem;
$input-btn-padding-x:       1rem;
$input-btn-line-height:     1.25;

$input-btn-padding-y-sm:    .25rem;
$input-btn-padding-x-sm:    .5rem;
$input-btn-line-height-sm:  1.25;

$input-btn-padding-y-lg:    1.063rem;
$input-btn-padding-x-lg:    1.875rem;
$input-btn-line-height-lg:  1.25;

// $btn-border-radius:              1.25em;
// $btn-border-radius-lg:           3.125em;
// $btn-border-radius-sm:           1.875em;

// $table-cell-padding:            .75rem;
// $table-cell-padding-sm:         .5rem;
// $table-bg:                      transparent;
// $table-accent-bg:               rgba($dark-blue,.05);
// $table-hover-bg:                rgba($black,.075);
// $table-active-bg:               $table-hover-bg;
// $table-border-width:            0;
// $table-border-color:            $gray-200;
// $table-head-bg:                 $gray-200;
// $table-head-color:              $gray-700;
// $table-inverse-bg:              $gray-900;
// $table-inverse-accent-bg:       rgba($white, .05);
// $table-inverse-hover-bg:        rgba($white, .075);
// $table-inverse-border-color:    lighten($gray-900, 7.5%);
// $table-inverse-color:           $body-bg;


//CARD
$card-spacer-y:            .75rem;
$card-spacer-x:            1.25rem;
$card-border-width:        1px;
$card-border-radius:       $border-radius;
$card-border-color:        $gray-200;
$card-inner-border-radius: calc(#{$card-border-radius} - #{$card-border-width});
$card-cap-bg:              rgba($black, .03);
$card-bg:                  transparent;


// FORM
$input-padding-y:       0.9375rem;
$input-padding-x:       0.9375rem;
$input-height-border:           $input-btn-border-width * 2;
$input-height-inner:            ($font-size-base * $input-btn-line-height) + ($input-padding-y * 2);
$input-height:                  calc(#{$input-height-inner} + #{$input-height-border});



// Grid

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
);
