footer{
  // background: $gray-900;
  background: theme-color('primary');
  background : -moz-linear-gradient(100.6% 94.54% 157.16deg,rgba(94, 112, 155, 1) 0.15%,rgba(39, 63, 102, 1) 34.3%,rgba(23, 41, 66, 1) 100%);
  background : -webkit-linear-gradient(157.16deg, rgba(94, 112, 155, 1) 0.15%, rgba(39, 63, 102, 1) 34.3%, rgba(23, 41, 66, 1) 100%);
  background : -webkit-gradient(linear,100.6% 94.54% ,3.56% 9.12% ,color-stop(0.0015,rgba(94, 112, 155, 1) ),color-stop(0.343,rgba(39, 63, 102, 1) ),color-stop(1,rgba(23, 41, 66, 1) ));
  background : -o-linear-gradient(157.16deg, rgba(94, 112, 155, 1) 0.15%, rgba(39, 63, 102, 1) 34.3%, rgba(23, 41, 66, 1) 100%);
  background : -ms-linear-gradient(157.16deg, rgba(94, 112, 155, 1) 0.15%, rgba(39, 63, 102, 1) 34.3%, rgba(23, 41, 66, 1) 100%);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#5E709B', endColorstr='#172942' ,GradientType=0)";
  background : linear-gradient(-67.16deg, rgba(94, 112, 155, 1) 0.15%, rgba(39, 63, 102, 1) 34.3%, rgba(23, 41, 66, 1) 100%);
  color: $white;
  padding: $grid-gutter-width 0;

  a{
    color: #fff;
    &:hover{
      color: #fff;
      text-decoration: underline;
    }
  }

  ul#menu-footer-links{
    li{

      a{
        font-size: 1.125em;
      }
      &:first-child{
        a{
          padding-left: 0;
        }
      }
    }
  }

  ul#menu-footer-rechts{
    li{
      a{
        font-size: 1.125em;
      }
      &:last-child{
        a{
          padding-right: 0;
        }
      }
    }
  }


  .nav{
    .nav-item{
      margin: auto 0;
      &.btn{
        margin: auto 0;
        padding: 0;
        &:last-child{
          padding-right: 0;
        }
        a{
          @extend .btn;
          @extend .btn-secondary;
          color: #fff;
          padding-left: $grid-gutter-width/2;
          padding-right: $grid-gutter-width/2;
          // border-radius: 10px;
          &:hover{
            color: #fff;
          }
        }
        &.btn-outline{
          a{
            @extend .btn-outline-white;
            color: #fff;
          }
        }
      }
    }
  }
}
