//https://google-webfonts-helper.herokuapp.com/f


/* ibm-plex-sans-300 - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/ibm-plex-sans-v4-latin-300.eot'); /* IE9 Compat Modes */
  src: local('IBM Plex Sans Light'), local('IBMPlexSans-Light'),
       url('../fonts/ibm-plex-sans-v4-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/ibm-plex-sans-v4-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/ibm-plex-sans-v4-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/ibm-plex-sans-v4-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/ibm-plex-sans-v4-latin-300.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}

/* ibm-plex-sans-600 - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/ibm-plex-sans-v4-latin-600.eot'); /* IE9 Compat Modes */
  src: local('IBM Plex Sans SemiBold'), local('IBMPlexSans-SemiBold'),
       url('../fonts/ibm-plex-sans-v4-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/ibm-plex-sans-v4-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/ibm-plex-sans-v4-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/ibm-plex-sans-v4-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/ibm-plex-sans-v4-latin-600.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}

/* ibm-plex-serif-regular - latin */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/ibm-plex-serif-v5-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('IBM Plex Serif'), local('IBMPlexSerif'),
       url('../fonts/ibm-plex-serif-v5-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/ibm-plex-serif-v5-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/ibm-plex-serif-v5-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/ibm-plex-serif-v5-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/ibm-plex-serif-v5-latin-regular.svg#IBMPlexSerif') format('svg'); /* Legacy iOS */
}


b,
strong {
  font-weight: 700;
}

h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4,
h5,.h5,
h6,.h6{
  // margin-bottom: $grid-gutter-width/2;
  // @include media-breakpoint-down(md) {
  //   margin-bottom: $grid-gutter-width/3;
  // }
  margin-bottom: 1rem;
}

h1,.h1{
  // margin-bottom: $grid-gutter-width*1.5;
  @include media-breakpoint-down(md) {
    font-size: $h2-font-size;
    // margin-bottom: $grid-gutter-width;
  }
}

h2,.h2{
  // margin-bottom: $grid-gutter-width;
  @include media-breakpoint-down(md) {
    font-size: $h3-font-size;
    // margin-bottom: $grid-gutter-width/2;
  }
}

h3,.h3{
  @include media-breakpoint-down(md) {
    font-size: $h4-font-size;
  }
}

h4,.h4{
  // margin-bottom: ($grid-gutter-width/3)*2;
  color: theme-color("primary");
  @include media-breakpoint-down(md) {
    font-size: $h5-font-size;
    // margin-bottom: ($grid-gutter-width/6)*2;
  }
}


p{
  &.large{
    margin-bottom: $grid-gutter-width/2;
    @include media-breakpoint-up(md) {
      font-size: 1.25rem;
      line-height: 2.25rem;
      font-weight: 400;
      margin-bottom: $grid-gutter-width;
    }
  }
  &:last-child{
    margin: 0;
  }
}


.text-serif{
  font-family: $font-family-serif;
  font-weight: 400;
}