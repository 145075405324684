* {
  box-sizing: border-box;
  outline: none;
}

html, body {
  overflow-x: hidden;
  &.show-menu {
    overflow: hidden;
  }
}

body {
  main {
    scroll-behavior: smooth;
    .element {
      padding-bottom: $grid-gutter-width;
      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  &.error404{
    height: 100vh;;
    background: theme-color('primary');
    background : -moz-linear-gradient(100.6% 94.54% 157.16deg,rgba(94, 112, 155, 1) 0.15%,rgba(39, 63, 102, 1) 34.3%,rgba(23, 41, 66, 1) 100%);
    background : -webkit-linear-gradient(157.16deg, rgba(94, 112, 155, 1) 0.15%, rgba(39, 63, 102, 1) 34.3%, rgba(23, 41, 66, 1) 100%);
    background : -webkit-gradient(linear,100.6% 94.54% ,3.56% 9.12% ,color-stop(0.0015,rgba(94, 112, 155, 1) ),color-stop(0.343,rgba(39, 63, 102, 1) ),color-stop(1,rgba(23, 41, 66, 1) ));
    background : -o-linear-gradient(157.16deg, rgba(94, 112, 155, 1) 0.15%, rgba(39, 63, 102, 1) 34.3%, rgba(23, 41, 66, 1) 100%);
    background : -ms-linear-gradient(157.16deg, rgba(94, 112, 155, 1) 0.15%, rgba(39, 63, 102, 1) 34.3%, rgba(23, 41, 66, 1) 100%);
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#5E709B', endColorstr='#172942' ,GradientType=0)";
    background : linear-gradient(-67.16deg, rgba(94, 112, 155, 1) 0.15%, rgba(39, 63, 102, 1) 34.3%, rgba(23, 41, 66, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5E709B',endColorstr='#172942' , GradientType=1);
    header{
      display: none;
    }
    main{
      display: flex;
      height: 100%;
    }
  }

  &.home{
    main{
      .pb-row{
        display: flex;
        &:first-child().gradient{
          $jumbo-height: 670px;
          position: relative;
          padding-top: 100px;
          @include media-breakpoint-up(lg) {
            padding-top: 200px;
          }
          margin-bottom: calc(#{$jumbo-height} * 0.2208955);
          overflow: visible;
          &::before{
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            display: block;
            width: 75%;
            height: 75%;
            @include media-breakpoint-up(md) {
              width: 50%;
              height: calc(100% + 22.08955%);
            }
            background-image: url('../images/x.svg');
            background-repeat: no-repeat;
            background-size: auto 100%;
            background-position: top right;
          }
          .container{
            align-self: flex-end !important;
            .row{
              .col-lg-7{
                @include media-breakpoint-up(xl) {
                  padding-right: 100px;
                }
              }
            }
            .element{
              figure{
                margin: 30px -100px -150px -5px !important;
                @include media-breakpoint-up(md) {
                  position: absolute;
                  top: 100px;
                  left: 20px;
                  width: 750px;
                  margin: 0 !important;
                }
                @include media-breakpoint-up(lg) {
                  top: -20px;
                  left: 0;
                }
              }
            }
          }
        }
        &.usp{
          position: relative;
          background-repeat: no-repeat;
          background-size: auto 100%;
          background-position: top center;
          @include media-breakpoint-up(md) {
            height: 420px;
            .row{
              padding: 0 !important;
            }
          }
          .container{
            align-self: center !important;
          }
          @include media-breakpoint-down(sm) {
            &:nth-child(even){
              .row{
                flex-direction: column-reverse;
              }
            }

          }
          @include media-breakpoint-up(md) {
            &:nth-child(even){
              background-image: url('../images/line-right.svg');
            }
            &:nth-child(odd){
              background-image: url('../images/line-left.svg');
            }
          }
        }
      }
    }
  }
}

a {
  color: theme-color("secondary");
}

// CARD
.card {
  .card-body {
    h1 {
      margin: 0;
    }
    h2 {
      font-weight: 400;
    }
    .card-title {
      font-size: 1.125em;
      line-height: 2.25em;
    }
    // .card-text{
    //   font-size: 1.125em;
    //   line-height: 2em;
    //   font-weight: 400;
    //   font-weight: 300;
    // }
  }
  &.shadow {
    box-shadow: 0 4px 16px 6px rgba(238, 238, 238, 0.5);
    border-color: #fff;
  }
  &.max-width {
    @include media-breakpoint-up(md) {
      max-width: 50vw;
    }
    margin: 0 auto;
  }
  + .card {
    margin-top: $grid-gutter-width;
  }
}

.pt-10 {
  @include media-breakpoint-down(md) {
    padding-top: $spacer * 4 !important;
  }
}

.pb-10 {
  @include media-breakpoint-down(md) {
    padding-bottom: $spacer * 4 !important;
  }
}

.shareIcon {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  line-height: 30px;
  text-align: center;
  display: inline-block;
  background: #fff;
  color: $gray-900;
  &:hover {
    color: $gray-900;
    background: $gray-500;
  }
  + .shareIcon {
    margin-left: $grid-gutter-width/4;
  }
}

.alert {
  p {
    &:last-child() {
      margin: 0;
    }
  }
  a {
    color: inherit;
    text-decoration: underline;
    font-weight: 900;
  }
}

.medium-zoom-overlay {
  z-index: 1031;
}
.medium-zoom-image.medium-zoom-image--opened {
  z-index: 1032;
}

[data-toggle="collapse"] {
  cursor: pointer;
}

.collapsing,
.collapse.show {
  + [data-toggle="collapse"] {
    display: none;
  }
}

.langSwitch {
  position: absolute;
  right: $grid-gutter-width + 10px;
  top: 20px;
  color: $gray-400;
  &:hover {
    color: $blue;
    strong {
      color: $blue;
    }
  }
  strong {
    color: $gray-500;
  }
}

// form{
//   &.wpcf7-form{
//     .form-group{
//       label{
//         .required{
//           color: $red;
//         }
//       }
//       .help-block{
//         color: $dark-blue;
//         font-size: .8rem;
//         &.wpcf7-not-valid-tip{
//           color: $red;
//         }
//       }
//       &.has-error{
//         input{
//           border-color: $red;
//         }
//       }
//     }
//   }
// // }

// .wpcf7 {
//   .screen-reader-response {
//     position: absolute;
//     overflow: hidden;
//     clip: rect(1px, 1px, 1px, 1px);
//     height: 1px;
//     width: 1px;
//     margin: 0;
//     padding: 0;
//     border: 0;
//   }
//   .form-inline img.ajax-loader {
//     display: inline;
//   }
//   .ajax-loader {
//     visibility: hidden;
//     display: inline-block;
//     background-image: url('/wp-content/plugins/contact-form-7/images/ajax-loader.gif');
//     width: 16px;
//     height: 16px;
//     border: none;
//     padding: 0;
//     margin: 0 0 0 4px;
//     vertical-align: middle;
//     &.is-active {
//       visibility: visible;
//     }
//   }
//   div.ajax-error, .wpcf7-display-none {
//     display: none;
//   }
//   .placeheld {
//     color: #888;
//   }
//   .wpcf7-recaptcha iframe {
//     margin-bottom: 0;
//   }
//   input[type="file"] {
//     cursor: pointer;
//     &:disabled {
//       cursor: default;
//     }
//   }
//   .form-inline .form-group {
//     max-width: 250px;
//   }
//   .input-group-addon {
//     img {
//       height: 100%;
//       width: auto;
//       max-width: none !important;
//       border-radius: 5px;
//     }
//     &.input-group-has-image {
//       padding: 0;
//     }
//   }
// }

.gutter-lg {
  padding-right: ($grid-gutter-width-lg / 2);
  padding-left: ($grid-gutter-width-lg / 2);
  .row {
    margin-right: ($grid-gutter-width-lg / -2);
    margin-left: ($grid-gutter-width-lg / -2);
  }
  @include make-grid-columns($grid-columns, $grid-gutter-width-lg, $grid-breakpoints);
}

.img-fluid {
  width: 100%;
}

figure {
  position: relative;
  figcaption {
    position: absolute;
    bottom: $grid-gutter-width/2;
    right: -$grid-gutter-width/2;
    background-color: theme-color("secondary");
    color: white;
    padding: 10px ($grid-gutter-width / 2);
    max-width: 50%;
    &.large {
      font-size: 1.5em;
      bottom: $grid-gutter-width;
    }
  }
  & .medium-zoom-image {
    + figcaption {
      pointer-events: none;
    }
  }
  .figure-overlay{
    font-size: 4em;
    i{
      position: absolute;
      top: 50%;
      left: 20%;
      transform: translateY(-50%);
      text-align: center;
      width: 1.25em;
      height: 1.25em;
      box-sizing: content-box;
      border-radius: 50%;
      line-height: 1.25em;
      background-color: rgba(theme-color("secondary"), 0.96);
      box-shadow: 0 0 3em 0 rgba(theme-color("dark"), 0.95);
      padding: 60px;
      text-shadow: 0px 0px 20px darken(theme-color("secondary"), 5);
      transition: all 150ms ease-in;

      &.fa-play{
        &::before{
          margin-left: 0.1em;
        }
      }
    }
  }
  &:hover{
    i{
      box-shadow: 0 0 2em 0 rgba(theme-color("dark"), 0.95);
      background-color: theme-color("secondary");
    }
  }
}


*[data-toggle='modal'],
button {
  cursor: pointer;
}
.videoModal{
  .modal-body {
    position:relative;
    padding:0px;
  }
  .close {
    position:absolute;
    right:-30px;
    top:0;
    z-index:999;
    font-size:2rem;
    font-weight: normal;
    color:#fff;
    opacity:1;
  }
}

// .section.pricing {
//   background: $hex-blue;
//   background: linear-gradient(to right, #0062E6, #33AEFF);
// }

.pricing {
  .card {
    border-color: $hex-blue;
    color: $hex-blue;
    text-align: center;
    transition: background 150ms ease-in;
    &:hover{
      text-decoration: none;
      background: lighten($hex-blue, 65%);
    }

    &.bestValue {
      position: relative;
      background: $hex-blue;
      color: $white;
      border: 0;
      &:hover{
        background: lighten($hex-blue, 7%);
      }
      .card-highlighted{
        @include media-breakpoint-up(lg) {
          position: absolute;
          top:-50px;
        }
        width: 100%;
        height: 50px;
        line-height: 50px;
        font-weight: 700;
        text-transform: uppercase;
        background: $hex-yellow;
      }
      .card-title {
        color: $white;
      }
      .card-price{
        &-month,
        &-year {
          color: $white;
        }
      }
      ul{
        li{
          // color: lighten($hex-blue, 50%);
          border-color: lighten($hex-blue, 5%);
        }
      }
    }
    &-body{
      padding: $grid-gutter-width-lg;
      .card-icon{
        font-size: 5em;
      }
      .card-title {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 2em;
      }
      .card-price{
        &-month,
        &-year {
          color: $hex-blue;
        }

        &-year{
          .price{
            font-size: 2.5em;
          }
        }
      }
      ul {
        margin: 0 0 $grid-gutter-width 0;
        padding: 0;
        li {
          margin: 0;
          padding: 1em 0;
          list-style: none;
          border-bottom: 1px solid $gray-200;
        }
      }
    }
  }
}
