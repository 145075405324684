
.btn-outline-white {
  @include button-outline-variant(#fff, $hex-yellow);
}

.btn-lg {
  @include button-size($input-btn-padding-y-lg, $input-btn-padding-x-lg, $font-size-lg, $input-btn-line-height-lg, $btn-border-radius-lg);
  font-weight: $font-weight-bold;
}

.btn-sm {
  @include button-size($input-btn-padding-y-sm, $input-btn-padding-x-sm, $font-size-sm, $input-btn-line-height-sm, $btn-border-radius-sm);
}

.btn-primary,
.btn-blue,
.btn-greendark{
  color: #fff;
  &:hover{
    color: #fff;
  }
}

.btn{
  + .btn{
    margin-left: $grid-gutter-width/2;
  }
}

.btn-outline-other{
  &:hover{
    background-color: var(--bg-color) !important;
    color: var(--color) !important;
  }
}

.btn-other{
  &:hover{
    background-color: var(--bg-hover) !important;
  }
}

.btn-wrap-outer{
  display: block;
  @include media-breakpoint-up(sm) {
    display: flex;
  }
  .btn-wrap{
    display: flex;
    margin-bottom: $grid-gutter-width/2;
    .btn{
      // margin: 0 auto;
    }
    + .btn-wrap{
      @include media-breakpoint-up(sm) {
        margin-left: $grid-gutter-width/2;
      }
    }
  }
}

.btn-secondary{
  color: #fff;
}

.btn small {
  color: #284067;
  font-size: 14px;
  display: block;
}