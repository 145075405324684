.pb-row{

  &:not(.has-slider){
    padding: 0 $grid-gutter-width;
  }
  @include media-breakpoint-up(md) {
    padding: 0;
  }

  &.px-window {
    position: relative;
    background: transparent;
  }
  &.photo-cover{
    background-size: cover;
    background-position: 50%;
  }
  &.photo-static{
    background-repeat: no-repeat;
    background-position: top center;
    background-size: auto;
  }
  &.color{
    &.gray{
      background-color: $gray-200;
    }
    &.gray-dark{
      color: #fff;
      background-color: $gray-800;
      h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6{
        color: #fff;
      }
    }
    &.gradient{
      background : -moz-linear-gradient(100.6% 94.54% 157.16deg,rgba(94, 112, 155, 1) 0.15%,rgba(39, 63, 102, 1) 34.3%,rgba(23, 41, 66, 1) 100%);
      background : -webkit-linear-gradient(157.16deg, rgba(94, 112, 155, 1) 0.15%, rgba(39, 63, 102, 1) 34.3%, rgba(23, 41, 66, 1) 100%);
      background : -webkit-gradient(linear,100.6% 94.54% ,3.56% 9.12% ,color-stop(0.0015,rgba(94, 112, 155, 1) ),color-stop(0.343,rgba(39, 63, 102, 1) ),color-stop(1,rgba(23, 41, 66, 1) ));
      background : -o-linear-gradient(157.16deg, rgba(94, 112, 155, 1) 0.15%, rgba(39, 63, 102, 1) 34.3%, rgba(23, 41, 66, 1) 100%);
      background : -ms-linear-gradient(157.16deg, rgba(94, 112, 155, 1) 0.15%, rgba(39, 63, 102, 1) 34.3%, rgba(23, 41, 66, 1) 100%);
      -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#5E709B', endColorstr='#172942' ,GradientType=0)";
      background : linear-gradient(-67.16deg, rgba(94, 112, 155, 1) 0.15%, rgba(39, 63, 102, 1) 34.3%, rgba(23, 41, 66, 1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5E709B',endColorstr='#172942' , GradientType=1);
    
      color: $white;
      h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6{
        color: #fff;
      }
      .btn-primary{
        border:  rgba(255, 255, 255, 0.5);
        background: rgba(255, 255, 255, 0.5);
        color: $blue;
        &:hover{
          color: #fff;
          background: $blue;
          border-color: $blue;
        }
      }
      .btn-outline-primary{
        border-color: #fff;
        color: #fff;
        &:hover{
          border-color: $blue;
        }
      }
      .card{
        border-color: $white;
        .card-body{
          color: $white;
          h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6{
            color: $white;
          }
        }
      }
      + .gradient{
        .container{
          .py-md-10{
            padding-top: 0 !important;
          }
        }
      }
    }
  }
}
