.icons {
  height: 10em;
  @include media-breakpoint-up(md) {
    height: auto;
  }
  .icon {
    position: absolute;
    top: 0;
    left: 0;
    transition: all 200ms ease;
    transition-property: top, left;
    @include media-breakpoint-up(md) {
      margin-left: -0.5em;
      margin-top: -0.5em;
      margin-right: 0;
    }
  }
}

.usp {
  &:nth-child(even) {
    .icons {
      .icon {
        &1 {
          top: 15%;
          left: 5%;
          @include media-breakpoint-up(md) {
            top: 50%;
            left: 85%;
          }
          @include media-breakpoint-up(lg) {
            left: 70%;
          }
        }
        &2 {
          top: -40%;
          left: 50%;
          @include media-breakpoint-up(md) {
            top: 10%;
            left: 60%;
          }
          @include media-breakpoint-up(lg) {
            top: -15%;
            left: 40%;
          }
        }
        &3 {
          top: -50%;
          left: -5%;
          @include media-breakpoint-up(md) {
            top: 100%;
            left: 60%;
          }
          @include media-breakpoint-up(lg) {
            top: 95%;
            left: 40%;
          }
        }
        &4 {
          top: 30%;
          left: 80%;
          @include media-breakpoint-up(md) {
            top: -10%;
            left: 100%;
          }
          @include media-breakpoint-up(lg) {
            top: 5%;
            left: 90%;
          }
        }
      }
    }
  }

  &:nth-child(odd) {
    .icons {
      .icon {
        &1 {
          top: 15%;
          left: 5%;
          @include media-breakpoint-up(md) {
            top: 50%;
            left: 10%;
          }
          @include media-breakpoint-up(lg) {
            left: 40%;
          }
        }
        &2 {
          top: -40%;
          left: 50%;
          @include media-breakpoint-up(md) {
            top: 5%;
            left: 30%;
          }
          @include media-breakpoint-up(lg) {
            top: -5%;
            left: 50%;
          }
        }
        &3 {
          top: -50%;
          left: -5%;
          @include media-breakpoint-up(md) {
            top: 90%;
            left: 25%;
          }
          @include media-breakpoint-up(lg) {
            left: 10%;
          }
        }
        &4 {
          top: 30%;
          left: 80%;
          @include media-breakpoint-up(md) {
            top: -20%;
            left: 0%;
          }
          @include media-breakpoint-up(lg) {
            top: -10%;
            top: 10%;
          }
        }
      }
    }
  }
}
