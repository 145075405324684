// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

// Boostrap override

.form-control {
  padding: $input-padding-y $input-padding-x;
}


// File input
// input[type='file'] {
//   width: 0.1px;
//   height: 0.1px;
//   opacity: 0;
//   overflow: hidden;
//   position: absolute;
//   z-index: -1;
//   + label {
//     max-width: 80%;
//     font-size: 1.25rem;
//     font-weight: 700;
//     text-overflow: ellipsis;
//     white-space: nowrap;
//     cursor: pointer;
//     display: inline-block;
//     overflow: hidden;
//     padding: 0.625rem 1.25rem;
//   }
//   &:focus + label, &.has-focus + label {
//     outline: 1px dotted #000;
//     outline: -webkit-focus-ring-color auto 5px;
//   }
//   + label {
//     color: #d3394c;
//   }
//   &:focus + label, &.has-focus + label {
//     color: #722040;
//   }
//   + label {
//     &:hover {
//       color: #722040;
//     }
//     figure {
//       width: 100px;
//       height: 100px;
//       border-radius: 50%;
//       background-color: #d3394c;
//       display: block;
//       padding: 20px;
//       margin: 0 auto 10px;
//     }
//   }
//   &:focus + label figure, &.has-focus + label figure {
//     background-color: #722040;
//   }
//   + label {
//     &:hover figure {
//       background-color: #722040;
//     }
//     svg {
//       width: 100%;
//       height: 100%;
//       fill: #f1e5e6;
//     }
//   }
// }
